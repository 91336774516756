module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DigiFix-Theme","short_name":"digifix","start_url":"/","background_color":"#ffffff","theme_color":"sand","display":"minimal-ui","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-159418955-1","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"www.moss-bryggeri.no"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
